<template>
  <v-app light>
    <v-main>
      <div class="my-6 mx-8">
        <v-card class="d-flex flex-column" elevation="0">
          <v-card color="primary" class="pa-4 d-flex flex-row" elevation="0" dark>
            <div
              class="d-flex flex-row justify-space-between align-center"
              style="width: 100%"
            >
              <h3>
                <v-icon small>mdi-application-cog</v-icon>
                เลือกชั้นข้อมูลที่ต้องการแสดงผล MUC Conflict layer
              </h3>
              <v-btn
                to="/msp/muc/matrix"
                target="_blank"
                color="warning"
                large
                elevation="0"
                >ไปยังหน้าวิเคราะห์ MUC</v-btn
              >
            </div>
          </v-card>
          <v-row class="pa-12">
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="newLayerList"
                :single-select="singleSelect"
                item-key="Alternate"
                class="selected-layer-table elevation-1"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:item.IsSelected="{ item }">
                  <v-simple-checkbox v-model="item.IsSelected"></v-simple-checkbox>
                </template>
                <template v-slot:item.Name="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.Name"
                    @save="save"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    {{ props.item.Name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.Name"
                        :rules="[max100chars]"
                        label="Edit"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.Mobility="{ item }">
                  <v-select
                    v-model="item.Mobility"
                    :items="mobilityList"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </template>
                <template v-slot:item.Vertical="{ item }">
                  <v-select
                    v-model="item.Vertical"
                    :items="verticalList"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </template>
                <template v-slot:item.Timescale="{ item }">
                  <v-select
                    v-model="item.Timescale"
                    :items="timescaleList"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </template>
                <template v-slot:item.Spatial="{ item }">
                  <v-select
                    v-model="item.Spatial"
                    :items="spatialList"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </template>
                <template v-slot:item.GeoCode="{ item }">
                  <v-select
                    v-model="item.GeoCode"
                    :items="provinceList"
                    item-text="name"
                    item-value="value"
                    dense
                    outlined
                    hide-details
                    multiple
                  >
                  </v-select>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="d-flex justify-end">
              <!-- <v-btn @click="resetConfigValue" class="mr-4" outlined x-large :disabled="isLoading"
                >ยกเลิกและกลับค่าเริ่มต้น</v-btn
              > -->
              <v-btn
                @click="postConfigValue"
                :loading="isLoading"
                :disabled="isLoading"
                color="success"
                x-large
                >บันทึกการเปลี่ยนแปลง
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-dialog v-model="isLoading" persistent max-width="380">
          <v-card>
            <v-card-title class="text"> กำลังอัพเดทข้อมูล </v-card-title>
            <v-card-text
              >ระบบกำลังดำเนินการ หากมีการอัพเดทหลายชั้นข้อมูล
              อาจมีการดำเนินการนานกว่าปกติ</v-card-text
            >
          </v-card>
        </v-dialog>

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import provinceList from "@/assets/muc/province.json";
export default {
  name: "MucConflict",
  data: () => ({
    singleSelect: false,
    provinceList: provinceList,
    selected: [],
    headers: [
      {
        text: "เลือก",
        sortable: false,
        value: "IsSelected",
      },
      {
        text: "ชื่อชั้นข้อมูล",
        align: "start",
        sortable: false,
        value: "Name",
        width: "260px",
      },
      {
        text: "mobility",
        align: "center",
        sortable: false,
        value: "Mobility",
        width: "140px",
      },
      {
        text: "vertical",
        align: "center",
        sortable: false,
        value: "Vertical",
        width: "260px",
      },
      {
        text: "timescale",
        align: "center",
        sortable: false,
        value: "Timescale",
        width: "40px",
      },
      {
        text: "spatial",
        align: "center",
        sortable: false,
        value: "Spatial",
        width: "40px",
      },
      {
        text: "จังหวัด",
        align: "center",
        sortable: false,
        value: "GeoCode",
        width: "200px",
      },
      { text: "Alternate", align: "center", sortable: false, value: "Alternate" },
    ],
    oldLayerList: [],
    newLayerList: [],
    mobilityList: ["mobility", "fixed"],
    verticalList: ["whole water column", "surface", "bottom/sea floor"],
    timescaleList: [1, 2, 3],
    spatialList: [1, 2, 3],
    snack: false,
    snackColor: "",
    snackText: "",
    isLoading: false,
    max100chars: (v) => v.length <= 100 || "ชื่อยาวเกินไป",
  }),
  components: {},
  created() {},
  mounted() {
    this.getMucList();
  },
  computed: {},
  methods: {
    getMucList() {
      let self = this;
      self.isLoading = true;
      let url = process.env.VUE_APP_WEBSERVICE + "/get/layerConfig";
      self.axios
        .get(url)
        .then((response) => {
          self.newLayerList = response.data.data;
          self.oldLayerList = JSON.parse(JSON.stringify(self.newLayerList));
          self.isLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          self.isLoading = false;
        });
    },
    resetConfigValue() {
      let self = this;
      self.newLayerList = JSON.parse(JSON.stringify(self.oldLayerList));
    },
    findDifference() {
      let self = this;

      // A comparer used to determine if two entries are equal.
      const isSameUser = (a, b) =>
        a.Name === b.Name &&
        a.Mobility === b.Mobility &&
        a.Vertical === b.Vertical &&
        a.Timescale === b.Timescale &&
        a.Spatial === b.Spatial &&
        self.compareArrays(a.GeoCode, b.GeoCode) &&
        a.IsSelected === b.IsSelected;

      // Get items that only occur in the left array,
      // using the compareFunction to determine equality.
      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          (leftValue) =>
            !right.some((rightValue) => compareFunction(leftValue, rightValue))
        );

      const onlyInB = onlyInLeft(self.newLayerList, self.oldLayerList, isSameUser);

      const result = [...onlyInB];
      return result;
    },
    async postConfigValue() {
      let self = this;
      self.isLoading = true;
      let data = await self.findDifference();
      console.log("diff :" + data);

      if (data.length === 0) {
        self.isLoading = false;
        alert("nothing changed");
        return 0;
      }
      data = JSON.stringify(data);

      var config = {
        method: "post",
        url: process.env.VUE_APP_WEBSERVICE + "/set/layerConfig",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      self
        .axios(config)
        .then(function (response) {
          console.log(response);
          self.getMucList();
          self.isLoading = false;
          // window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
          self.isLoading = false;
        });
    },
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "กรุณากดบันทึกการเปลี่ยนแปลงอีกครั้ง";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    },
    compareArrays(a, b) {
      return a.toString() === b.toString();
    },
  },
};
</script>
<style scoped>
.header {
  color: white;
}

.muc-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
::v-deep .v-application--wrap {
  min-height: unset;
  height: 100%;
}
.selected-layer-table {
  height: 500px;
  overflow-y: scroll;
}
</style>
