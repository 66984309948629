<template>
  <div>
    <!-- กราฟความเร็ว -->
    <apexchart
      type="scatter"
      :options="velocityChartOptions"
      :series="velocityChartSeries"
      height="400"
      class="mb-8"
    ></apexchart>

    <!-- กราฟทิศทาง -->
    <apexchart
      type="scatter"
      :options="directionChartOptions"
      :series="directionChartSeries"
      height="400"
    ></apexchart>
  </div>
</template>

<script>
// import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    // apexchart: VueApexCharts,
  },
  props: {
    oilData: {
      type: String,
      default: "",
    },
  },
  watch: {
    oilData(value) {
      // Parse the oilData when it changes
      if (value) {
        this.updateChartSeries(value);
      }
    },
  },
  data() {
    return {
      // ตั้งค่ากราฟความเร็ว
      velocityChartOptions: {
        chart: {
          type: "line",
          height: 400,
          zoom: { enabled: true },
          animations: { enabled: false },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "datetime",
          title: {
            text: "เวลา",
            style: {
              fontSize: "14px",
            },
          },
        },
        yaxis: {
          title: {
            text: "ความเร็ว (km/h)",
            style: {
              fontSize: "14px",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy HH:mm",
          },
        },
        title: {
          text: "กราฟแสดงความเร็วตามเวลา",
          align: "center",
          style: {
            fontSize: "16px",
          },
        },
      },
      velocityChartSeries: [
        {
          name: "ความเร็ว",
          data: [],
        },
      ],

      // ตั้งค่ากราฟทิศทาง
      directionChartOptions: {
        chart: {
          type: "line",
          height: 400,
          zoom: { enabled: true },
          animations: { enabled: false },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "datetime",
          title: {
            text: "เวลา",
            style: {
              fontSize: "14px",
            },
          },
        },
        yaxis: {
          title: {
            text: "ทิศทาง (องศา)",
            style: {
              fontSize: "14px",
            },
          },
          min: 0,
          max: 360,
          tickAmount: 8,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy HH:mm",
          },
        },
        title: {
          text: "กราฟแสดงทิศทางตามเวลา",
          align: "center",
          style: {
            fontSize: "16px",
          },
        },
      },
      directionChartSeries: [
        {
          name: "ทิศทาง",
          data: [],
        },
      ],
    };
  },
  methods: {
    // Parse CSV data into an array of objects
    parseCsvData(csvString) {
      const rows = csvString.split("\n");
      const headers = rows.shift().split(",");
      return rows
        .filter((row) => row.trim()) // กรองแถวว่างออก
        .map((row) => {
          const values = row.split(",");
          return headers.reduce((acc, header, index) => {
            acc[header.trim()] = values[index]?.trim();
            return acc;
          }, {});
        });
    },

    // Update chart series with parsed data
    updateChartSeries(csvData) {
      const parsedData = this.parseCsvData(csvData);

      // แปลงข้อมูลสำหรับกราฟความเร็ว
      const velocityData = parsedData.map((item) => ({
        x: new Date(item.time).getTime(),
        y: parseFloat(item.velocity),
      }));

      // แปลงข้อมูลสำหรับกราฟทิศทาง
      const directionData = parsedData.map((item) => ({
        x: new Date(item.time).getTime(),
        y: parseFloat(item.direction),
      }));

      // อัพเดทข้อมูลทั้งสองกราฟ
      this.velocityChartSeries = [
        {
          name: "ความเร็ว",
          data: velocityData,
        },
      ];

      this.directionChartSeries = [
        {
          name: "ทิศทาง",
          data: directionData,
        },
      ];
    },
  },
  mounted() {
    // Initialize chart with provided oilData
    if (this.oilData) {
      this.updateChartSeries(this.oilData);
    }
  },
};
</script>

<style scoped>
/* เพิ่ม margin ระหว่างกราฟ */
.mb-8 {
  margin-bottom: 2rem;
}
</style>
